<template>
  <div v-if="item">
    <b-card
      class="d-flex inline-block"
    >
      <template #header>
        <slot name="header"></slot>
      </template>

      <template #default>
        <slot name="body">
          <card-body
            :item="item"
          />
        </slot>
      </template>
      
      <template #footer>
        <slot name="footer"
          @openAuth="$emit('openAuth')"
          @delete="$emit('delete')"
          @refresh="$emit('refresh')"
        >
          <default-footer 
            :active="item.token ? true : false"
            @openAuth="$emit('openAuth')"
            @delete="$emit('delete')"
            @refresh="$emit('refresh')"
            @getExternalLink="$emit('getExternalLink')"
          />
        </slot>
      </template>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import CardBody from '@/layouts/components/ThirdPartyApps/CardBody.vue'
import DefaultFooter from '@/layouts/components/ThirdPartyApps/DefaultFooter.vue'

  export default {
    components: {
      CardBody,
      BCard,
      DefaultFooter,
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value){
          this.$emit('input', value)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>