<template>
  <div class="card-body-height" v-if="item.token">
    <b-form-group :label="`${$t(item.label)} Token`" label-for="token">
      <b-input-group >
        <b-input-group-prepend class="cursor-pointer" @click="copyToken" is-text>
          <feather-icon icon="CopyIcon" />
        </b-input-group-prepend>
        <b-form-input
          id="token"
          label="token"
          :value="item.token"
          :type="passwordFieldType"
          disabled
        />
        <b-input-group-append
          class="cursor-pointer"
          @click="togglePasswordVisibility"
          is-text
        >
          <feather-icon :icon="passwordToggleIcon" />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <p class="pt-1">
      {{ isExpired ? $t("apps.card.expired") : $t("apps.card.expires_on") }} <br />
      <strong :class="isExpired ? 'text-primary' : 'text-secondary'">{{ item.valid_until }}</strong>
    </p>
  </div>
  <b-row align-v="center" class="d-flex justify-content-center card-body-height" v-else>
    <h2 class="text-secondary ">
      {{ $t('apps.card.not_linked') }}
    </h2>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BCard,
  BImg,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { makeToast } from "@/layouts/components/Popups";

export default {
  components: {
    BCol,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BImg,
    BButton,
    BInputGroupAppend,
    BFormGroup
  },
  props: {
    item: {
      type: undefined,
      default: undefined
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    isExpired() {
      if (!this.item.valid_until) {
        return "text-primary"
      }
      return new Date() > new Date(this.item.valid_until)
    }
  },
  methods: {
    copyToken() {
      this.$copyText(this.item.token)
        .then(() => {
          makeToast({
            title: this.$t("apps.toast.copy.success.title"),
            text: this.$t("apps.toast.copy.success.message"),
            variant: "success",
            icon: "ChackIcon",
          });
        })
        .catch(() => {
          makeToast({
            title: this.$t("apps.toast.copy.error.title"),
            text: this.$t("apps.toast.copy.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body-height {
  height: 160px
}
</style>