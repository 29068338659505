<template>
  <div>
    <div v-if="active" class="d-flex justify-content-between">
      <b-button variant="danger" class="btn-icon" @click="$emit('delete')">
        <b-icon
          icon="trash"
        />
        {{ $t("common.terms.delete") }}
      </b-button>

      <b-button variant="warning" class="btn-icon" @click="$emit('refresh')">
        <b-icon
          icon="arrow-clockwise"
        />
        {{ $t("common.terms.refresh") }}
      </b-button>
    </div>
    <div v-else class="d-flex justify-content-end">
      <!-- <b-button 
        class="text-uppercase" 
        variant="info"
        @click="$emit('getExternalLink')"
      >
        External Link 
      </b-button> -->
      <b-button 
        class="text-uppercase" 
        variant="success"
        @click="$emit('openAuth')"
      >
        {{ $t("common.terms.add") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BImg,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BImg,
    BButton,
    BInputGroupAppend,
  },
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>